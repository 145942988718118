import React from 'react';

function LeftChevron() {
  return (
    <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8516 0.334557C12.0531 0.485713 12.1035 0.687255 12.1035 0.888797C12.1035 1.09034 12.0531 1.29188 11.902 1.44304L2.73183 11.369L11.8516 21.3453C12.1539 21.6476 12.1539 22.1514 11.8012 22.4538C11.4989 22.7561 10.995 22.7561 10.6927 22.4034L1.01872 11.9232C0.716412 11.6209 0.716412 11.1674 1.01872 10.8651L10.6927 0.384942C11.0454 0.0322443 11.5493 0.0322443 11.8516 0.334557Z" fill="white" />
    </svg>
  );
}

export default LeftChevron;
