/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import FacebookAdsSkeleton from '../../common/containers/FacebookAdSkeleton';
import { ILoadingAdsProps } from './props';
import { sendURLToGetRequest } from '../../common/sendInfo';
import { getAdsFromRequest } from '../../common/getInfo';
import RightUnbounceCTA from '../RightUnbouceCTA';
import { IAdObj } from '../../common/types';
import { correctOrder } from '../../common/constants';

function LoadingAds({ stepObj, setStepObj, setAds } : ILoadingAdsProps) {
  const requestFetchedGeneratorRef = useRef(false);
  const requestFetchedAdsRef = useRef(false);
  const [requestID, setRequestID] = useState('');

  const fetchRequestGenerator = async (url: string) => {
    const response = await sendURLToGetRequest({ url });
    if (response.status === 429) {
      setStepObj({ step: 3, url: '' });
      return;
    }
    const result = await response.json() as any;
    if (result.message) {
      window.location.href = `http://unbounce.com/url-to-ads-error?message=${result.message}`;
    } else {
      setRequestID(result.data);
    }
  };

  const fetchRequestAds = async (generatorID: string) => {
    const response = await getAdsFromRequest(generatorID);
    if (response.status === 429) {
      setStepObj({ step: 3, url: '' });
      return;
    }
    const result = await response.json() as any;
    if (result.message) {
      window.location.href = `http://unbounce.com/url-to-ads-error?message=${result.message}`;
    } else if (result.data.complete) {
      setAds({
        content: result.data.contents.sort((contentA: IAdObj, contentB: IAdObj) => correctOrder.indexOf(contentA.templateName) - correctOrder.indexOf(contentB.templateName)),
        domain: result.data.domain,
        companyName: result.data.companyName,
        id: result.data.id,
      });
      window.ap3c.activity({
        activity_id: 'act:cm:generate-url-to-ad',
        attributes: {
          'str:cm:urltoads': result.data.domain,
        },
      });
      setStepObj((prev) => ({ ...prev, step: 2 }));
    } else {
      setTimeout(() => {
        fetchRequestAds(generatorID);
      }, 2000);
    }
  };

  useEffect(() => {
    if (requestFetchedGeneratorRef.current) return;
    const { url, step } = stepObj;
    if (url && step === 1) {
      requestFetchedGeneratorRef.current = true;
      fetchRequestGenerator(url);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepObj]);

  useEffect(() => {
    if (requestID) {
      if (requestFetchedAdsRef.current) return;
      requestFetchedAdsRef.current = true;
      fetchRequestAds(requestID);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestID]);

  return (
    <motion.div
      className="mt-6 md:mt-10 lg:mt-16 xl:mt-24 2xl:mt-32"
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ ease: 'easeIn', duration: 0.9 }}
    >
      <div className="container mx-auto text-center">
        <p className="italic p-10 text-lg font-semibold text-ugray animate-pulse">
          Please wait while we generate your ads. This could take up to a few minutes.
        </p>
        <div className="hidden scale-75 md:w-1/2 lg:w-1/3 md:inline-block p-6">
          <FacebookAdsSkeleton />
        </div>
        <div className="hidden w-1/3 lg:inline-block">
          <FacebookAdsSkeleton />
        </div>
        <div className="scale-75 w-full md:w-1/2 lg:w-1/3 inline-block">
          <FacebookAdsSkeleton />
        </div>
      </div>
      <RightUnbounceCTA />
    </motion.div>
  );
}

export default LoadingAds;
