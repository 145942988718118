/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import TemplateResult from '../../common/TemplateResult';
import { IAdsProps } from './types';
import LeftUrlInput from '../LeftUrlInput';
import RightUnbounceCTA from '../RightUnbouceCTA';
import LeftArrow from '../../common/SVGS/leftArrow';
import LeftChevron from '../../common/SVGS/leftChevron';
import RightChevron from '../../common/SVGS/rightChevron';
import Redirect from '../../common/Redirect';

function Ads({ ads, setStepObj, usedURL }: IAdsProps) {
  const website = ads?.domain || '';
  const [[page, direction], setPage] = useState([0, 0]);
  const adsComponent = useMemo(() => (ads ? ads.content.map((cont) => (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{
        duration: 0.2,
      }}
      key={cont.id}
    >

      <TemplateResult data={cont} companyName={ads.companyName} website={ads.domain} />
    </motion.div>

  )) : []), [ads]);
  const compIndex = wrap(0, adsComponent.length, page);
  const prevCompIndex = wrap(0, adsComponent.length, page + 1);
  const nextCompIndex = wrap(0, adsComponent.length, page - 1);
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  if (ads) {
    return (
      <div className="absolute inset-0">
        <div className="relative h-full">
          <button
            className="relative flex items-center lg:absolute top-10 left-10 z-40 group"
            type="button"
            onClick={() => {
              window.location.href = 'https://unbounce.com/url-to-ads';
            }}
          >
            <LeftArrow />
            <span className="ml-1 font-source text-xl text-mine-shaft group-hover:text-ubblue ">Home</span>
          </button>
          <div className="hidden relative min-h-[600px] h-full overflow-visible lg:flex justify-center items-center container mx-auto">
            <AnimatePresence initial={false} custom={direction}>
              <div
                className="inline-block max-w-[600px] pointer-events-none scale-[40%] blur-sm grayscale opacity-50 absolute left-0 z-0 -translate-x-20"
              >
                {adsComponent[nextCompIndex]}
              </div>
              <motion.div
                className="sm:max-w-[800px] z-10 bg-white"
                initial="enter"
                animate="center"
                exit="exit"
                drag="x"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                // Snap the component back to the center
                // if it hasn't paginated
                dragConstraints={{
                  left: 0, right: 0, top: 0, bottom: 0,
                }}
                // This will be used for components to resolve all
                // other variants, in this case initial and animate.
                custom={direction}
              >
                {adsComponent[compIndex]}
              </motion.div>
              <div className="pointer-events-none max-w-[600px] scale-[40%] blur-sm grayscale opacity-50 absolute right-0 z-0 translate-x-20">
                {adsComponent[prevCompIndex]}
              </div>
            </AnimatePresence>
            <div className="absolute top-1/2 left-0 ml-2">
              <div
                className="bg-shamrock rounded-full h-12 w-12 flex justify-center items-center"
                role="button"
                tabIndex={0}
                onKeyUp={() => paginate(-1)}
                onClick={() => paginate(-1)}
              >
                <LeftChevron />
              </div>
            </div>
            <div className="absolute top-1/2 right-0 mr-2">
              <div
                className="bg-shamrock rounded-full h-12 w-12 flex justify-center items-center"
                role="button"
                tabIndex={0}
                onKeyUp={() => paginate(1)}
                onClick={() => paginate(1)}
              >
                <RightChevron />
              </div>
            </div>
          </div>
          <div className="lg:hidden p-4">
            {adsComponent}
            <div className="h-14" />
          </div>
          <LeftUrlInput website={website} setStep={setStepObj} companyName={ads.companyName} usedURL={usedURL} />
        </div>
        <RightUnbounceCTA />
      </div>
    );
  }
  return (<Redirect url="https://unbounce.com/url-to-ads" />);
}

export default Ads;
