import React from 'react';

function RightChevron() {
  return (
    <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.34429 22.4538C1.14274 22.3026 1.04197 22.1011 1.04197 21.8995C1.04197 21.698 1.14274 21.4964 1.2939 21.3453L10.4137 11.4194L1.2939 1.44304C0.991587 1.14072 0.991587 0.636869 1.34429 0.334557C1.6466 0.0322443 2.15045 0.0322443 2.45276 0.384942L12.1268 10.8651C12.4291 11.1674 12.4291 11.6209 12.1268 11.9232L2.45276 22.4034C2.15045 22.7561 1.6466 22.7561 1.34429 22.4538Z" fill="white" />
    </svg>
  );
}

export default RightChevron;
