import React from 'react';

function TwitterAdsBrand() {
  return (
    <div className="flex items-center">
      <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.587 6.54245C25.587 6.82118 25.587 7.04416 25.587 7.32288C25.587 15.0714 19.7337 23.9349 8.97494 23.9349C5.63024 23.9349 2.56427 22.9872 0 21.3149C0.44596 21.3706 0.89192 21.4264 1.39362 21.4264C4.12513 21.4264 6.63365 20.4787 8.64047 18.9178C6.0762 18.8621 3.90215 17.1897 3.17746 14.8485C3.56768 14.9042 3.90215 14.9599 4.29236 14.9599C4.79407 14.9599 5.35152 14.8485 5.79748 14.737C3.12172 14.1795 1.1149 11.8382 1.1149 8.99523V8.93949C1.89533 9.38545 2.84299 9.60843 3.79066 9.66417C2.17405 8.60502 1.17064 6.82118 1.17064 4.81436C1.17064 3.69946 1.44937 2.69605 1.95107 1.85987C4.84981 5.37181 9.19792 7.7131 14.0477 7.99182C13.9362 7.54586 13.8805 7.0999 13.8805 6.65394C13.8805 3.42073 16.5005 0.800716 19.7337 0.800716C21.4061 0.800716 22.9112 1.46966 24.0261 2.6403C25.3082 2.36158 26.5904 1.85987 27.7053 1.19093C27.2593 2.58456 26.3674 3.69946 25.141 4.42414C26.3116 4.31265 27.4823 3.97818 28.4857 3.53222C27.7053 4.70287 26.7019 5.70628 25.587 6.54245Z" fill="#4FABE7" />
      </svg>
      <p className="ml-1 font-barlow text-scorpion text-2xl">Twitter Ad</p>
    </div>
  );
}

export default TwitterAdsBrand;
