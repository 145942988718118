/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useState } from 'react';
import UBSCLogoWhite from '../../assets/UBSmartCopyWhite.svg';
import UBSCLogo from '../../assets/UBSmartCopy.svg';
import RightArrow from '../../common/SVGS/rightArrow';

function RightUnbounceCTA() {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="fixed inset-x-0 bottom-0 z-40">
      <div className={clsx('absolute -z-10 w-md h-md bg-crow rounded-full mx-auto left-1/2 -translate-x-1/2 lg:hidden', openModal && '-translate-y-10')} />
      <div className={clsx('absolute -z-10 w-md h-md lg:bg-pampas rounded-full right-0 translate-x-64')} />
      <div className="max-w-sm mx-auto lg:ml-auto lg:mr-0 text-center  px-4 pb-6">
        <button type="button" className={clsx('', openModal ? 'hidden' : 'inline-block')} onClick={() => setOpenModal(true)}>
          <svg
            className="mt-4 fill-white hover:fill-shamrock"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.91406 8.67188L8.83984 2.30859L15.8008 8.67188C15.9062 8.77734 16.0469 8.8125 16.1875 8.8125C16.3281 8.8125 16.4688 8.77734 16.5742 8.63672C16.7852 8.42578 16.7852 8.07422 16.5391 7.86328L9.22656 1.11328C9.01562 0.902344 8.69922 0.902344 8.48828 1.11328L1.17578 7.86328C0.929688 8.07422 0.929688 8.42578 1.14062 8.63672C1.35156 8.88281 1.70312 8.88281 1.91406 8.67188ZM9.22656 7.86328C9.01562 7.65234 8.69922 7.65234 8.48828 7.86328L1.17578 14.6133C0.929688 14.8242 0.929688 15.1758 1.14062 15.3867C1.35156 15.6328 1.70312 15.6328 1.91406 15.4219L8.83984 9.05859L15.8008 15.4219C15.9062 15.5273 16.0469 15.5625 16.1523 15.5625C16.3281 15.5625 16.4688 15.5273 16.5742 15.3867C16.7852 15.1758 16.7852 14.8242 16.5391 14.6133L9.22656 7.86328Z" fill="inherit" />
          </svg>
        </button>
        <div className="lg:p-6 z-30 font-source text-mine-shaft text-center lg:text-right">
          <button
            type="button"
            className={clsx('ml-auto items-center lg:hidden text-white font-source group', openModal ? 'inline-block' : 'hidden')}
            onClick={() => setOpenModal(false)}
          >
            <svg
              className="inline-block fill-white group-hover:fill-shamrock"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.0742 11.7344C11.8633 11.9453 11.4766 11.9453 11.2656 11.7344L6.625 7.05859L1.94922 11.7344C1.73828 11.9453 1.35156 11.9453 1.14062 11.7344C0.929688 11.5234 0.929688 11.1367 1.14062 10.9258L5.81641 6.25L1.14062 1.60938C0.929688 1.39844 0.929688 1.01172 1.14062 0.800781C1.35156 0.589844 1.73828 0.589844 1.94922 0.800781L6.625 5.47656L11.2656 0.800781C11.4766 0.589844 11.8633 0.589844 12.0742 0.800781C12.2852 1.01172 12.2852 1.39844 12.0742 1.60938L7.39844 6.25L12.0742 10.9258C12.2852 11.1367 12.2852 11.5234 12.0742 11.7344Z" fill="inherit" />
            </svg>
            <span className="ml-1 group-hover:text-shamrock">Close</span>
          </button>
          <img src={UBSCLogoWhite} alt="Unbounce Smart Copy Logo" className="h-6 lg:h-8 mx-auto mt-4 lg:hidden" />
          <img src={UBSCLogo} alt="Unbounce Smart Copy Logo" className="h-6 lg:h-8 ml-auto hidden lg:inline-block" />
          <p className={clsx(openModal ? 'block' : 'hidden lg:block', 'text-white lg:text-mine-shaft lg:font-semibold text-sm my-2')}>
            Generate your ad copy, blog posts, landing page copy, and more
          </p>
          <a
            href="https://copy.unbounce.com/register?utm_medium=tool&utm_source=urltoads"
            className={clsx(openModal ? 'inline-block' : 'hidden lg:inline-block', 'text-md lg:text-xl text-shamrock group-hover:text-jungle-green lg:text-ubblue lg:group-hover:text-ubblue-hover font-semibold')}
          >
            Sign up for free
            <RightArrow />
          </a>
        </div>

      </div>
    </div>
  );
}

export default RightUnbounceCTA;
