import React, { useEffect } from 'react';
import { IRedirectProps } from './types';

function Redirect({ url }: IRedirectProps) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return (<h5 className="font-barlow text-mine-shaft text-3xl text-center mt-6">Redirecting...</h5>);
}

export default Redirect;
