import React from 'react';
import { clsx } from 'clsx';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { ITooltipProps } from './props';

function Tooltip({ text, className }: ITooltipProps) {
  return (
    <div className={clsx('inline-block', className)}>
      <Tippy content={text} theme="light-border" placement="auto">
        <FontAwesomeIcon icon={faCircleInfo} />
      </Tippy>
    </div>
  );
}

export default Tooltip;
