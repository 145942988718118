export const correctOrder = [
  'Twitter Ads v2',
  'LinkedIn ads v2',
  'Google Ads v2',
  'Facebook ads v2',
  'Bing Ads v2',
];

export const correctOrderObj = [
  {
    templateName: 'Twitter Ads v2',
  },
  {
    templateName: 'LinkedIn ads v2',
  },
  {
    templateName: 'Google Ads v2',
  },
  {
    templateName: 'Facebook ads v2',
  },
  {
    templateName: 'Bing Ads v2',
  },
];
