import { getAds, getCount } from './URLS';

const getFetch = (url: string) => fetch(url, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const getAdsFromRequest = (generatorID: string) => getFetch(getAds(generatorID));
export const getAdsCount = () => getFetch(getCount());
