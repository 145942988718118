import React from 'react';

function LeftArrow() {
  return (
    <svg className="inline-block fill-ubblack group-hover:fill-ubblue" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.75 7.25C16.75 7.56641 16.4688 7.8125 16.1523 7.8125H2.89844L7.82031 13.0859C8.03125 13.2969 8.03125 13.6484 7.78516 13.8594C7.67969 13.9648 7.53906 14 7.39844 14C7.22266 14 7.08203 13.9648 6.97656 13.8242L1.14062 7.63672C0.929688 7.42578 0.929688 7.10938 1.14062 6.89844L6.97656 0.710938C7.1875 0.464844 7.53906 0.464844 7.78516 0.675781C8.03125 0.886719 8.03125 1.23828 7.82031 1.44922L2.89844 6.6875H16.1523C16.4688 6.6875 16.75 6.96875 16.75 7.25Z" />
    </svg>
  );
}

export default LeftArrow;
