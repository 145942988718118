import React from 'react';
import { clsx } from 'clsx';
import IButtonProps from './props';

function Button({
  children,
  className: classCSS,
  onClick,
  disabled,
  type,
  loading,
  onMouseEnter,
  onMouseLeave,
  id,
}: IButtonProps) {
  return disabled ? (
    <button
      type="button"
      className={clsx(
        'text-center font-source font-semibold text-sm px-8 py-2 rounded select-none pointer-events-none',
        (type === 'primary' || type === 'danger')
          && 'border border-usilver text-white bg-usilver',
        type === 'secondary' && 'border border-ugray text-ugray',
        type === 'alternative' && 'text-white bg-silver-alto',
        type === 'outline' && 'border border-silver text-silver',
        type === 'link' && 'text-usilver',
        classCSS,
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {loading ? (
        <>
          <i className="fa fa-spinner fa-spin mr-1" />
          {children}
        </>
      ) : (
        children
      )}
    </button>
  ) : (
    <button
      id={id}
      type="button"
      className={clsx(
        'text-center font-source font-semibold text-sm px-8 py-2 rounded select-none',
        type === 'primary'
          && 'border border-ubblue text-white bg-ubblue betterhover:hover:bg-klein-blue',
        type === 'secondary'
          && 'text-ubblue border border-ubblue betterhover:hover:bg-ubblue betterhover:hover:text-white',
        type === 'alternative'
          && 'text-ubblack bg-shamrock betterhover:hover:bg-jungle-green',
        type === 'outline'
          && 'text-tundora border border-scorpion betterhover:hover:bg-scorpion betterhover:hover:text-white',
        type === 'grayOutline'
          && 'text-silver-chalice border border-silver-chalice betterhover:hover:bg-ugray betterhover:hover:text-white',
        type === 'link' && 'text-ubblue betterhover:hover:text-klein-blue',
        type === 'danger'
          && 'border border-radical-red text-white bg-radical-red betterhover:hover:bg-amaranth',
        type === 'whiteOutline'
          && 'text-white border-2 border-white betterhover:hover:bg-white betterhover:hover:text-dodger-blue',
        classCSS,
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {loading ? (
        <>
          <i className="fa fa-spinner fa-spin mr-1" />
          {children}
        </>
      ) : (
        children
      )}
    </button>
  );
}

export default Button;
