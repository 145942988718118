/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadingAds from './containers/LoadingAds';
import { IAdsObj, IStepObj } from './common/types';
import Ads from './containers/Ads';
import DailyLimit from './containers/DailyLimit';
import { validateWebsite, resultWebsite } from './common/validation';

function App() {
  const [stepObj, setStepObj] = useState<IStepObj>({
    step: 0,
    url: '',
  });
  const [ads, setAds] = useState<null | IAdsObj>(null);
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url');
  useLayoutEffect(() => {
    if (url) {
      if (validateWebsite(url)) {
        setStepObj({ step: 1, url: resultWebsite(url) });
      } else {
        window.location.href = 'https://unbounce.com/url-to-ads';
      }
    } else {
      window.location.href = 'https://unbounce.com/url-to-ads';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <div>
      {stepObj.step === 1 && <LoadingAds setStepObj={setStepObj} stepObj={stepObj} setAds={setAds} />}
      {stepObj.step === 2 && <Ads ads={ads} setStepObj={setStepObj} usedURL={stepObj.url} />}
      {stepObj.step === 3 && <DailyLimit setStep={setStepObj} />}
    </div>
  );
}

export default App;
