import React from 'react';

function FacebookAdsBrand() {
  return (
    <div className="flex items-center">
      <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.0955 14.3399C28.0955 6.70287 21.9078 0.515171 14.2707 0.515171C6.63365 0.515171 0.44596 6.70287 0.44596 14.3399C0.44596 21.2523 5.46301 26.994 12.0967 27.9975V18.3536H8.58473V14.3399H12.0967V11.3297C12.0967 7.87351 14.1592 5.92244 17.2809 5.92244C18.8418 5.92244 20.4027 6.20116 20.4027 6.20116V9.60161H18.6746C16.9465 9.60161 16.389 10.6608 16.389 11.7757V14.3399H20.2354L19.6222 18.3536H16.389V27.9975C23.0227 26.994 28.0955 21.2523 28.0955 14.3399Z" fill="#4993FF" />
      </svg>
      <p className="ml-1 font-barlow text-scorpion text-2xl">Facebook Ad</p>
    </div>
  );
}

export default FacebookAdsBrand;
