import React from 'react';

function RightArrow() {
  return (
    <svg className="ml-2 inline-block fill-shamrock betterhover:group-hover:text-jungle-green lg:fill-ubblue betterhover:group-hover:text-ubblue-hover" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5742 7.63672L9.73828 13.8242C9.63281 13.9648 9.45703 14 9.31641 14C9.17578 14 9.03516 13.9648 8.92969 13.8594C8.68359 13.6484 8.68359 13.2969 8.89453 13.0859L13.8164 7.8125H0.5625C0.246094 7.8125 0 7.56641 0 7.28516C0 7.00391 0.246094 6.6875 0.5625 6.6875H13.8164L8.89453 1.44922C8.68359 1.23828 8.68359 0.886719 8.92969 0.675781C9.17578 0.464844 9.52734 0.464844 9.73828 0.710938L15.5742 6.89844C15.7852 7.10938 15.7852 7.42578 15.5742 7.63672Z" />
    </svg>
  );
}

export default RightArrow;
