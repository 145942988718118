import { url2ads } from './URLS';

const sendFetch = (url: string, data = {}) => fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  body: JSON.stringify(data),
});

// eslint-disable-next-line import/prefer-default-export
export const sendURLToGetRequest = (body: { url: string }) => sendFetch(url2ads(), body);
