import React from 'react';

function GoogleAdsBrand() {
  return (
    <div className="flex items-center">
      <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5838_5829)">
          <path d="M4.75696 28.0322C3.49534 28.0322 2.28538 27.531 1.39328 26.6389C0.501178 25.7468 0 24.5369 0 23.2753C0 22.0136 0.501178 20.8037 1.39328 19.9116C2.28538 19.0195 3.49534 18.5183 4.75696 18.5183C6.01858 18.5183 7.22854 19.0195 8.12064 19.9116C9.01274 20.8037 9.51392 22.0136 9.51392 23.2753C9.51392 24.5369 9.01274 25.7468 8.12064 26.6389C7.22854 27.531 6.01858 28.0322 4.75696 28.0322ZM27.9043 20.8956L18.3904 4.4165C17.7596 3.32363 16.7205 2.52611 15.5016 2.19938C14.2828 1.87265 12.9841 2.04348 11.8912 2.67428C10.7983 3.30509 10.0008 4.3442 9.67406 5.56302C9.34733 6.78184 9.51816 8.08054 10.149 9.17341L19.6641 25.6525C20.302 26.7313 21.3397 27.5148 22.5519 27.8329C23.7642 28.151 25.0529 27.9781 26.1384 27.3516C27.2238 26.7251 28.0182 25.6956 28.3491 24.4868C28.6799 23.2781 28.5194 21.9876 27.9043 20.8956ZM8.93595 6.52381L1.85997 18.7776C2.72362 18.2207 3.72934 17.9243 4.75696 17.9237C7.80141 17.9142 10.2572 20.4901 10.1014 23.5309L13.9272 16.9034L9.63403 9.47071C9.12008 8.57705 8.87751 7.55306 8.93595 6.52381Z" fill="#55A159" />
          <path d="M27.9043 20.8956L18.3904 4.4165C17.7596 3.32363 16.7205 2.52611 15.5016 2.19938C14.2828 1.87265 12.9841 2.04348 11.8912 2.67428C10.7983 3.30509 10.0008 4.3442 9.67406 5.56302C9.34733 6.78184 9.51816 8.08054 10.149 9.17341L19.6641 25.6525C20.302 26.7313 21.3397 27.5148 22.5519 27.8329C23.7642 28.151 25.0529 27.9781 26.1384 27.3516C27.2238 26.7251 28.0182 25.6956 28.3491 24.4868C28.6799 23.2781 28.5194 21.9876 27.9043 20.8956ZM8.93595 6.52381L1.85997 18.7776C2.72362 18.2207 3.72934 17.9243 4.75696 17.9237C7.80141 17.9142 10.2572 20.4901 10.1014 23.5309L13.9272 16.9034L9.63403 9.47071C9.12008 8.57705 8.87751 7.55306 8.93595 6.52381Z" fill="#EABA40" />
          <path d="M27.9043 20.8956L18.3904 4.4165C17.7596 3.32363 16.7205 2.52611 15.5016 2.19938C14.2828 1.87265 12.9841 2.04348 11.8912 2.67428C10.7983 3.30509 10.0008 4.3442 9.67406 5.56302C9.34733 6.78184 9.51816 8.08054 10.149 9.17341L19.6641 25.6525C20.302 26.7313 21.3397 27.5148 22.5519 27.8329C23.7642 28.151 25.0529 27.9781 26.1384 27.3516C27.2238 26.7251 28.0182 25.6956 28.3491 24.4868C28.6799 23.2781 28.5194 21.9876 27.9043 20.8956Z" fill="#507FE5" />
        </g>
        <defs>
          <clipPath id="clip0_5838_5829">
            <rect width="28.5414" height="28.5414" fill="white" transform="translate(0 0.763672)" />
          </clipPath>
        </defs>
      </svg>
      <p className="ml-1 font-barlow text-scorpion text-2xl">Google Ad</p>
    </div>
  );
}

export default GoogleAdsBrand;
