import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { ICopiedProps } from './props';

function Copied({ textToCopy }: ICopiedProps) {
  const [copied, setCopied] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [copied]);
  return (
    <div className="absolute inset-0 bg-white/95 opacity-0 betterhover:hover:opacity-100 rounded-lg">
      <div className="flex h-full w-full justify-center items-center">
        <AnimatePresence>
          {copied ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0 }}
              className="text-shamrock text-xl"
            >
              <svg className="mx-auto" width="30" height="29" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.75 19.5C13.3281 19.5 13 19.875 13 20.25V21C13 21.8438 12.2969 22.5 11.5 22.5H4C3.15625 22.5 2.5 21.8438 2.5 21V9C2.5 8.20312 3.15625 7.5 4 7.5H9.25C9.625 7.5 10 7.17188 10 6.75C10 6.375 9.625 6 9.25 6H3.95312C2.3125 6 0.953125 7.35938 0.953125 9L1 21C1 22.6875 2.3125 24 4 24H11.5C13.1406 24 14.5 22.6875 14.5 21V20.25C14.5 19.875 14.125 19.5 13.75 19.5ZM24.5312 4.07812L20.9219 0.46875C20.6406 0.1875 20.2656 0 19.8438 0H14.5C12.8125 0 11.5 1.35938 11.5 3V15C11.5 16.6875 12.8125 18 14.5 18H22C23.6406 18 25 16.6875 25 15V5.15625C25 4.73438 24.8125 4.35938 24.5312 4.07812ZM20.5 2.15625L22.8438 4.5H20.5V2.15625ZM23.5 15C23.5 15.8438 22.7969 16.5 22 16.5H14.5C13.6562 16.5 13 15.8438 13 15V3C13 2.20312 13.6562 1.5 14.5 1.5H19V4.5C19 5.34375 19.6562 6 20.5 6H23.5V15Z" fill="#27CC8D" />
              </svg>
              <p className="p-2">Copied!</p>
            </motion.div>
          ) : (
            <motion.button
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0 }}
              type="button"
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(textToCopy);
              }}
              className="text-ubblue text-xl"
            >
              <svg className="mx-auto" width="30" height="29" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.75 19.5C13.3281 19.5 13 19.875 13 20.25V21C13 21.8438 12.2969 22.5 11.5 22.5H4C3.15625 22.5 2.5 21.8438 2.5 21V9C2.5 8.20312 3.15625 7.5 4 7.5H9.25C9.625 7.5 10 7.17188 10 6.75C10 6.375 9.625 6 9.25 6H3.95312C2.3125 6 0.953125 7.35938 0.953125 9L1 21C1 22.6875 2.3125 24 4 24H11.5C13.1406 24 14.5 22.6875 14.5 21V20.25C14.5 19.875 14.125 19.5 13.75 19.5ZM24.5312 4.07812L20.9219 0.46875C20.6406 0.1875 20.2656 0 19.8438 0H14.5C12.8125 0 11.5 1.35938 11.5 3V15C11.5 16.6875 12.8125 18 14.5 18H22C23.6406 18 25 16.6875 25 15V5.15625C25 4.73438 24.8125 4.35938 24.5312 4.07812ZM20.5 2.15625L22.8438 4.5H20.5V2.15625ZM23.5 15C23.5 15.8438 22.7969 16.5 22 16.5H14.5C13.6562 16.5 13 15.8438 13 15V3C13 2.20312 13.6562 1.5 14.5 1.5H19V4.5C19 5.34375 19.6562 6 20.5 6H23.5V15Z" fill="#0033FF" />
              </svg>
              <p className="p-2">Copy to Clipboard</p>
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Copied;
