import { websiteRegExp } from './regexp';

export const resultWebsite = (value: string) => {
  const matchWebsite = value.match(websiteRegExp);
  const resultUrl = matchWebsite?.length ? matchWebsite[0] : '';
  if (resultUrl.startsWith('http')) {
    return resultUrl;
  }
  return `https://${resultUrl}`;
};

export const validateWebsite = (value: string) => {
  const matchWebsite = value.match(websiteRegExp);
  return !!matchWebsite?.length;
};

export const stripProtocol = (url: string) => url.replaceAll('https://', '').replaceAll('http://', '');
