/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { ITwitterAdsProps } from './props';
import Copied from '../../Copied';

function TwitterAds({ content, companyName, website }: ITwitterAdsProps) {
  const { ad_text: adText, headline } = content.content;
  const [imgError, setImgError] = useState(false);
  const companyFL = useMemo(() => companyName.split(' ').map((word) => word[0]).join(''), [companyName]);

  return (
    <div className="shadow-xl border border-usilver rounded-lg text-mine-shaft relative lg:w-[600px]">
      <div className="flex items-center px-6 pt-5">
        <div className="flex h-9 w-9 rounded-full overflow-hidden items-center">
          <span className={clsx(imgError ? 'inline-block mx-auto' : 'hidden')}>{companyFL}</span>
          <img
            className={clsx(imgError ? 'hidden' : 'self-center')}
            src={`https://logo.clearbit.com/${website}`}
            alt={website}
            onError={() => setImgError(true)}
          />
        </div>
        <div className="ml-1 grow">
          <div className="flex items-center">
            <h6 className="font-semibold font-source text-sm">
              {companyName}
            </h6>
            <svg className="ml-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 0.75C7.98438 0.75 8.85938 1.32422 9.29688 2.17188C10.1992 1.87109 11.2109 2.08984 11.9492 2.80078C12.6602 3.51172 12.8516 4.55078 12.5781 5.45312C13.4258 5.89062 14 6.76562 14 7.75C14 8.76172 13.4258 9.63672 12.5781 10.0742C12.8789 10.9766 12.6602 11.9883 11.9492 12.6992C11.2109 13.4102 10.1992 13.6289 9.29688 13.3555C8.85938 14.2031 7.98438 14.75 7 14.75C5.98828 14.75 5.11328 14.2031 4.67578 13.3555C3.77344 13.6289 2.76172 13.4102 2.02344 12.6992C1.3125 11.9883 1.12109 10.9766 1.39453 10.0742C0.546875 9.63672 0 8.76172 0 7.75C0 6.76562 0.546875 5.89062 1.39453 5.45312C1.09375 4.55078 1.3125 3.51172 2.02344 2.80078C2.76172 2.08984 3.77344 1.87109 4.67578 2.17188C5.11328 1.32422 5.98828 0.75 7 0.75ZM9.625 6.90234C9.89844 6.65625 9.89844 6.24609 9.625 5.97266C9.37891 5.72656 8.96875 5.72656 8.72266 5.97266L6.125 8.59766L5.03125 7.50391C4.78516 7.25781 4.375 7.25781 4.12891 7.50391C3.85547 7.77734 3.85547 8.1875 4.12891 8.43359L5.66016 9.96484C5.90625 10.2383 6.31641 10.2383 6.5625 9.96484L9.625 6.90234Z" fill="#4993FF" />
            </svg>
          </div>
          <p className="font-source text-xs">
            @
            {companyName.replaceAll(' ', '_')}
          </p>
        </div>
        <div>
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            width="1em"
            height="1em"
            className="x1lliihq x1k90msu x2h7rmj x1qfuztq xcza8v6 x1qx5ct2 xw4jnvo"
          >
            <g fillRule="evenodd" transform="translate(-446 -350)">
              <path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
            </g>
          </svg>
        </div>
      </div>
      <p className="font-source font-sm px-8 py-4">
        {adText}
      </p>
      <div className="border-gray-200 border border-b-0 rounded-t-2xl bg-gray-200 opacity-80 flex justify-center items-center mx-8  aspect-[28/15]  ">
        <span className={clsx(imgError ? 'inline-block mx-auto text-8xl font-barlow' : 'hidden')}>{companyFL}</span>
        <div className={clsx(imgError ? 'hidden' : 'inline-block')}>
          <img className="grayscale mix-blend-multiply brightness-110 contrast-125" src={`https://logo.clearbit.com/${website}`} alt={website} />
        </div>
      </div>
      <div className="mx-8   text-xs py-3 px-7 flex justify-between items-center border border-b border-gray-200 rounded-b-2xl">
        <div className="font-source">
          <span className="text-silver-chalice text-sm">{website}</span>
          <p className="text-base ">{headline}</p>
        </div>
      </div>
      <div className="flex pl-4 py-4 text-sm justify-around">
        <p className="font-bold">
          85
          {' '}
          <span className="font-normal">Retweets</span>
        </p>
        <p className="font-bold">
          14
          {' '}
          <span className="font-normal">Quote Tweets</span>
        </p>
        <p className="font-bold">
          227
          {' '}
          <span className="font-normal">Likes</span>
        </p>

      </div>
      <div className=" border-silver-alto flex justify-around pt-3 pb-6">
        <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1.5C14.3242 1.5 17.8398 4.27734 17.8398 7.6875C17.8398 11.0977 14.3242 13.875 10 13.875C8.98047 13.875 7.99609 13.7344 7.04688 13.4531C6.73047 13.3477 6.34375 13.3828 6.0625 13.5938C5.28906 14.1562 4.02344 14.8242 2.44141 15C2.86328 14.4727 3.49609 13.5586 3.88281 12.5391C4.05859 12.1523 3.95312 11.7305 3.67188 11.3789C2.65234 10.2891 2.125 9.02344 2.125 7.6875C2.125 4.27734 5.64062 1.5 10 1.5ZM10 0.375C5.00781 0.375 1 3.67969 1 7.6875C1 9.375 1.66797 10.9219 2.82812 12.1523C2.33594 13.5234 1.24609 14.7188 1.21094 14.7188C0.964844 14.9648 0.929688 15.3164 1.03516 15.6328C1.17578 15.9492 1.49219 16.125 1.84375 16.125C3.98828 16.125 5.67578 15.2461 6.73047 14.5078C7.75 14.8242 8.83984 15 10 15C14.957 15 18.9648 11.7305 18.9648 7.6875C18.9648 3.67969 14.957 0.375 10 0.375Z" fill="#606060" />
        </svg>
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.1875 11.1875H17.9375V2.43359L14.9844 5.42188C14.8789 5.52734 14.7383 5.5625 14.5625 5.5625C14.4219 5.5625 14.2812 5.52734 14.1758 5.42188C13.9648 5.21094 13.9648 4.82422 14.1758 4.61328L18.1133 0.675781C18.3242 0.464844 18.7109 0.464844 18.9219 0.675781L22.8594 4.61328C23.0703 4.82422 23.0703 5.21094 22.8594 5.42188C22.6484 5.63281 22.2617 5.63281 22.0508 5.42188L19.0625 2.43359V11.75C19.0625 12.0664 18.8164 12.3125 18.5 12.3125H11.1875C10.9062 12.3125 10.625 12.0664 10.625 11.75C10.625 11.4688 10.9062 11.1875 11.1875 11.1875ZM0.675781 9.11328C0.886719 8.90234 1.27344 8.90234 1.48438 9.11328L4.4375 12.1016V2.75C4.4375 2.46875 4.71875 2.1875 5 2.1875H12.3125C12.6289 2.1875 12.875 2.46875 12.875 2.75C12.875 3.06641 12.6289 3.3125 12.3125 3.3125H5.5625V12.1016L8.55078 9.11328C8.76172 8.90234 9.14844 8.90234 9.35938 9.11328C9.57031 9.32422 9.57031 9.71094 9.35938 9.92188L5.42188 13.8594C5.31641 13.9648 5.17578 14 5 14C4.85938 14 4.71875 13.9648 4.61328 13.8594L0.675781 9.92188C0.464844 9.71094 0.464844 9.32422 0.675781 9.11328Z" fill="#606060" />
        </svg>
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.96484 2.625L9.38672 2.23828C10.5469 1.07812 12.1992 0.550781 13.7812 0.832031C16.207 1.21875 18 3.32812 18 5.78906V5.96484C18 7.44141 17.3672 8.84766 16.3125 9.83203L9.94922 15.7734C9.70312 16.0195 9.35156 16.125 9 16.125C8.61328 16.125 8.26172 16.0195 8.01562 15.7734L1.65234 9.83203C0.597656 8.84766 0 7.44141 0 5.96484V5.78906C0 3.32812 1.75781 1.21875 4.18359 0.832031C5.76562 0.550781 7.41797 1.07812 8.57812 2.23828L8.96484 2.625ZM8.96484 4.24219L7.76953 3.01172C6.89062 2.13281 5.625 1.71094 4.35938 1.92188C2.49609 2.23828 1.08984 3.85547 1.08984 5.78906V5.96484C1.08984 7.125 1.58203 8.21484 2.42578 8.98828L8.78906 14.9297C8.82422 15 8.89453 15 8.96484 15C9.07031 15 9.14062 15 9.17578 14.9297L15.5391 8.98828C16.3828 8.21484 16.875 7.125 16.875 5.96484V5.78906C16.875 3.85547 15.4688 2.23828 13.6055 1.92188C12.3398 1.71094 11.0742 2.13281 10.1953 3.01172L8.96484 4.24219Z" fill="#606060" />
        </svg>
        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.45312 0.425781L2.39062 5.48828C2.17969 5.69922 2.17969 6.08594 2.39062 6.29688C2.60156 6.50781 2.98828 6.50781 3.19922 6.29688L7.3125 2.18359V12.0625C7.3125 12.3789 7.55859 12.625 7.875 12.625C8.15625 12.625 8.4375 12.3789 8.4375 12.0625V2.18359L12.5156 6.29688C12.7266 6.50781 13.1133 6.50781 13.3242 6.29688C13.5352 6.08594 13.5352 5.69922 13.3242 5.48828L8.26172 0.425781C8.15625 0.320312 8.01562 0.25 7.875 0.25C7.69922 0.25 7.55859 0.320312 7.45312 0.425781ZM14.625 12.0625V15.4375C14.625 16.3867 13.8516 17.125 12.9375 17.125H2.8125C1.86328 17.125 1.125 16.3867 1.125 15.4375V12.0625C1.125 11.7812 0.84375 11.5 0.5625 11.5C0.246094 11.5 0 11.7812 0 12.0625V15.4375C0 17.0195 1.23047 18.25 2.8125 18.25H12.9375C14.4844 18.25 15.75 17.0195 15.75 15.4375V12.0625C15.75 11.7812 15.4688 11.5 15.1875 11.5C14.8711 11.5 14.625 11.7812 14.625 12.0625Z" fill="#606060" />
        </svg>
      </div>
      <Copied textToCopy={`${adText} - ${headline}`} />
    </div>
  );
}

export default TwitterAds;
