/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import Button from '../components/Button';
import InputField from '../components/InputField';
import { resultWebsite, stripProtocol, validateWebsite } from '../validation';
import { IInputURLProps } from './props';

function InputURL({ setStep, isTools, usedURL }: IInputURLProps) {
  /* States */
  const [urlPage, setUrlPage] = useState('');
  const [inputError, setInputError] = useState(false);
  const [, setCurrentQueryParams] = useSearchParams();

  /* Button Functions */
  /* Button OnClick (Generate Ads) Function */
  const handleClickGenerate = () => {
    const webValidation = validateWebsite(urlPage);
    if (!webValidation) {
      setInputError(true);
      return;
    }
    const website = resultWebsite(urlPage);
    setStep({ step: 1, url: website });
    setCurrentQueryParams(`url=${stripProtocol(website)}`);
  };

  /* Input Functions */
  /* Input OnChange Function */
  const handleChangeURL = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val === '' || val.length <= 255) {
      setUrlPage(val);
      setInputError(false);
    }
  };
    /* Input OnPaste Function */
  const handlePasteURL = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const data = evt.clipboardData
      .getData('text')
      .trim()
      .substring(0, 255);
    setUrlPage(data);
    if (!validateWebsite(data)) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };
  /* Input OnBlur Function */
  const handleBlurURL = (evt: React.FocusEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val && val.trim() !== '') {
      evt.preventDefault();
      const website = validateWebsite(val);
      if (!website) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };
  /* Input OnKeyup Function (Detect a enter and submit the content)  */
  const handleKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      handleClickGenerate();
    }
  };

  useEffect(() => {
    if (usedURL) {
      setUrlPage(usedURL);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(!isTools ? 'flex flex-wrap justify-center gap-6 items-center lg:mx-10 mb-10' : 'text-center lg:text-left')}>
      <div className={clsx(isTools ? 'text-left' : 'grow lg:mx-8')}>
        <InputField
          label={isTools ? 'Your website or landing page URL' : ''}
          classNameLabel={clsx('text-left', isTools ? 'font-semibold' : 'text-white')}
          name="urlpage"
          placeholder="e.g. unbounce.com/your-landing-page"
          value={urlPage}
          onChange={handleChangeURL}
          error={inputError}
          borderError={inputError}
          errorMsg="This is not a valid page URL"
          onPaste={handlePasteURL}
          onBlur={handleBlurURL}
          onKeyUp={handleKeyUp}
          errorAbsolute
        />
      </div>
      <Button
        type={isTools ? 'primary' : 'alternative'}
        className={clsx(isTools ? 'mt-4 ' : 'mt-1')}
        disabled={inputError}
        onClick={handleClickGenerate}
      >
        Generate Ads
      </Button>
    </div>
  );
}

export default InputURL;
