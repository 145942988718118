import React from 'react';

function BingAdsBrand() {
  return (
    <div className="flex items-center">
      <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.299611 0.585938C2.34219 1.3664 4.38625 2.14686 6.4318 2.92732C7.32376 3.2618 7.32376 3.2618 7.32376 4.24072V20.2089L16.1876 15.806C14.8429 15.2486 13.5909 14.7011 12.3232 14.1994C12.1436 14.1349 11.9804 14.0315 11.8455 13.8966C11.7106 13.7617 11.6072 13.5985 11.5427 13.4189C10.8392 11.7231 10.1022 10.0407 9.37971 8.35263C9.30836 8.18539 9.24703 8.01815 9.15003 7.7684C9.39644 7.83419 9.57037 7.87321 9.73872 7.92561C14.1138 9.2903 18.4855 10.6617 22.865 12.0096C23.2786 12.1379 23.4001 12.314 23.3946 12.741C23.37 14.7658 23.37 16.7909 23.3946 18.8164C23.3946 19.2077 23.2574 19.4062 22.9374 19.5968C17.9254 22.6444 12.9185 25.7026 7.91691 28.7717C7.74743 28.8754 7.59692 29.0081 7.43748 29.1274H7.21449C7.07922 29.0081 6.9374 28.8964 6.7897 28.7929C4.75939 27.5241 2.7313 26.2497 0.692071 25.0032C0.32191 24.7802 0.170278 24.536 0.171393 24.0845C0.187002 16.2531 0.190717 8.42176 0.182541 0.590398L0.299611 0.585938Z" fill="#5CABF3" />
      </svg>
      <p className="ml-1 font-barlow text-scorpion text-2xl">Bing Ad</p>
    </div>
  );
}

export default BingAdsBrand;
