import React from 'react';

function LinkedInAdsBrand() {
  return (
    <div className="flex items-center">
      <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.1899 0.853051H1.72809C0.78043 0.853051 0 1.68923 0 2.69264V24.043C0 25.0464 0.78043 25.8268 1.72809 25.8268H23.1899C24.1376 25.8268 24.9738 25.0464 24.9738 24.043V2.69264C24.9738 1.68923 24.1376 0.853051 23.1899 0.853051ZM7.52557 22.2591H3.8464V10.3854H7.52557V22.2591ZM5.68599 8.7131C4.4596 8.7131 3.51193 7.76543 3.51193 6.59479C3.51193 5.42414 4.4596 4.42073 5.68599 4.42073C6.85663 4.42073 7.8043 5.42414 7.8043 6.59479C7.8043 7.76543 6.85663 8.7131 5.68599 8.7131ZM21.4061 22.2591H17.6712V16.4616C17.6712 15.1238 17.6712 13.3399 15.7758 13.3399C13.8248 13.3399 13.546 14.845 13.546 16.4059V22.2591H9.86686V10.3854H13.3788V12.002H13.4345C13.9362 11.0544 15.1626 10.051 16.9465 10.051C20.6814 10.051 21.4061 12.5595 21.4061 15.737V22.2591Z" fill="#0a66c2" />
      </svg>
      <p className="ml-1 font-barlow text-scorpion text-2xl">LinkedIn Ad</p>
    </div>
  );
}

export default LinkedInAdsBrand;
