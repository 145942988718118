import React from 'react';
import ladyHappy from '../../assets/ladyHappy.png';
import { IDailyLimitProps } from './props';

function DailyLimit({ setStep }: IDailyLimitProps) {
  return (
    <div className="absolute inset-0">
      <div className="relative h-full text-center text-white overflow-clip flex justify-center">
        <div className="-z-10 absolute bg-crow rounded-full h-2xl w-2xl -translate-y-[820px]" />
        <div className="p-10 lg:p-0 w-md lg:mt-20">
          <h1 className="font-barlow font-semibold text-4xl text-white">Want to generate more ads?</h1>
          <p className="font-source  text-xl text-white my-10">
            You&apos;ve reached your limit, but no worries, you can keep generating your ads (and more) by signing up for Smart Copy
          </p>
          <a
            className="block font-barlow font-medium text-3xl text-shamrock betterhover:hover:text-jungle-green my-10"
            href="https://copy.unbounce.com/register?utm_medium=tool&utm_source=urltoads"
          >
            Sign up for free
          </a>

          <button
            className="text-white betterhover:hover:text-silver font-source font-semibold text-lg flex items-center mx-auto"
            type="button"
            onClick={() => setStep((prev) => ({ ...prev, step: 2 }))}
          >
            <svg className="mr-1 inline-block" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.75 7.25C16.75 7.56641 16.4688 7.8125 16.1523 7.8125H2.89844L7.82031 13.0859C8.03125 13.2969 8.03125 13.6484 7.78516 13.8594C7.67969 13.9648 7.53906 14 7.39844 14C7.22266 14 7.08203 13.9648 6.97656 13.8242L1.14062 7.63672C0.929688 7.42578 0.929688 7.10938 1.14062 6.89844L6.97656 0.710938C7.1875 0.464844 7.53906 0.464844 7.78516 0.675781C8.03125 0.886719 8.03125 1.23828 7.82031 1.44922L2.89844 6.6875H16.1523C16.4688 6.6875 16.75 6.96875 16.75 7.25Z" fill="#ffffff" />
            </svg>
            Go back to your ads
          </button>
        </div>
        <img className="hidden md:inline-block absolute h-2/3 left-0 bottom-0" src={ladyHappy} alt="Unbounce can make it for you" />
      </div>
    </div>
  );
}

export default DailyLimit;
