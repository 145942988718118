/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Copied from '../../Copied';
import { IGoogleAdsProps } from './props';

function GoogleAds({ content }: IGoogleAdsProps) {
  const { headlines, descriptions } = content.content;
  return (
    <div className="font-source border border-usilver rounded-lg text-mine-shaft py-7 px-6 shadow-lg relative">
      <h1 className="text-ubblue text-xl font-semibold">{headlines.join(' | ')}</h1>
      <p className="text-sm mt-3">{descriptions.join(' ')}</p>
      <Copied textToCopy={`${headlines.join(' | ')} -  ${descriptions.join(' ')}`} />
    </div>
  );
}

export default GoogleAds;
