import React, { useState } from 'react';
import clsx from 'clsx';
import InputURL from '../../common/InputURL';
import { ILeftUrlInputProps } from './props';

function LeftUrlInput({ website, setStep, usedURL }: ILeftUrlInputProps) {
  const [imgError, setImgError] = useState(false);

  return (
    <div className="relative lg:fixed lg:bottom-0 px-10 lg:p-6 left-0 pb-24 z-50">
      <div className={clsx('h-12 w-12 mb-4 rounded overflow-clip')}>
        {!imgError && (
        <img
          className={clsx('lg:inline-block self-center')}
          src={`https://logo.clearbit.com/${website}`}
          alt={website}
          onError={() => setImgError(true)}
        />
        )}
      </div>
      <InputURL setStep={setStep} isTools usedURL={usedURL} />
      <p className="mt-8 text-sm mb-10">
        Logos provided by
        <a href="https://clearbit.com" className="text-ubblue hover:text-ubblue-hover"> Clearbit</a>
      </p>
    </div>
  );
}

export default LeftUrlInput;
