/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import BingAds from './BingAds';
import FacebookAds from './FacebookAds';
import GoogleAds from './GoogleAds';
import LinkedInAds from './LinkedInAds';
import TwitterAds from './TwitterAds';
import { ITemplateResultProps } from './props';
import BingAdsBrand from '../BrandAds/BingAdsBrand';
import GoogleAdsBrand from '../BrandAds/GoogleAdsBrand';
import TwitterAdsBrand from '../BrandAds/TwitterAdsBrand';
import FacebookAdsBrand from '../BrandAds/FacebookAdsBrand';
import LinkedInAdsBrand from '../BrandAds/LinkedInAdsBrand';

function TemplateResult({ data, companyName, website }: ITemplateResultProps) {
  switch (data.templateName.toLowerCase()) {
    case 'google ads v2':
      return (
        <div className="">
          <div className="flex justify-center my-8">
            <GoogleAdsBrand />
          </div>

          <GoogleAds content={data} />
        </div>
      );
    case 'facebook ads v2':
      return (
        <div className="">
          <div className="flex justify-center my-8">
            <FacebookAdsBrand />
          </div>
          <FacebookAds content={data} companyName={companyName} website={website} />
        </div>
      );
    case 'linkedin ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <LinkedInAdsBrand />
          </div>
          <LinkedInAds content={data} companyName={companyName} website={website} />
        </div>
      );
    case 'bing ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <BingAdsBrand />
          </div>
          <BingAds content={data} />

        </div>
      );
    case 'twitter ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <TwitterAdsBrand />
          </div>
          <TwitterAds content={data} companyName={companyName} website={website} />
        </div>
      );
    default:
      return null;
  }
}

export default TemplateResult;
